import {BadgeEvent, BadgeValue, InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subject, takeUntil} from 'rxjs';
import {BadgeValueService} from 'src/app/services/badge-values.service';
import {AppState} from 'src/app/store/states/app.state';

@Component({
  selector: 'ku-betriebsauswahl.page',
  templateUrl: './betriebsauswahl.page.component.html',
  styleUrls: ['./betriebsauswahl.page.component.scss'],
})
export class BetriebsauswahlPageComponent implements OnInit, OnDestroy {
  private readonly _unsubscribe$ = new Subject<void>();
  private store = inject(Store<AppState>);
  protected showError = false;
  public inhabersLoaded = true;
  protected badgeCounts: BadgeValue[] = [];


  constructor(
    private badgeValueService: BadgeValueService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.select(InhaberEntitiesSelectors.isCurrentInhaberValid).pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(isCurrentInhaberValid => {
      this.showError = !isCurrentInhaberValid;
    });

    this.badgeValueService.badgeValues$.pipe().subscribe(badgeValues => {
      this.badgeCounts = badgeValues;
    });
  }

  badgeClick(event: BadgeEvent) {
    this.router.navigate(['to-assign/inhaber/' + event.betriebId]);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}